import { forwardRef, ForwardRefRenderFunction } from 'react';

import { CurrencyInput, CurrencyInputProps, PercentageInput } from 'components';

interface Props extends CurrencyInputProps {
  mask?: 'currency' | 'percentage';
  maxValue?: number;
}

const PricingInputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref,
) => {
  const { mask = 'currency', maxValue, onValueChange, ...rest } = props;

  const onChange = (value: string | undefined) => {
    if (!maxValue) return onValueChange?.(value);

    if (value) {
      const parsedValue = Number(value.replace(',', '.'));

      if (Number.isNaN(parsedValue)) return onValueChange?.(value);

      if (parsedValue > maxValue) return onValueChange?.(String(maxValue));
    }

    onValueChange?.(value);
  };

  if (mask === 'percentage') {
    return (
      <PercentageInput
        _focus={{ transform: 'scale(1.05)' }}
        allowNegativeValue={false}
        decimalsLimit={1}
        h="3rem"
        w="full"
        bg="white"
        textAlign="center"
        fontFamily="heading"
        fontSize="1.75rem"
        fontWeight="bold"
        shadow="0 0 1.25rem #00000067"
        onValueChange={onChange}
        ref={ref}
        {...rest}
      />
    );
  }

  return (
    <CurrencyInput
      _focus={{ transform: 'scale(1.05)' }}
      allowNegativeValue={false}
      h="3rem"
      w="full"
      bg="white"
      textAlign="center"
      fontFamily="heading"
      fontSize="1.75rem"
      fontWeight="bold"
      shadow="0 0 1.25rem #00000067"
      onValueChange={onChange}
      ref={ref}
      {...rest}
    />
  );
};

export const PricingInput = forwardRef(PricingInputBase);
