import { ReactNode } from 'react';
import {
  Divider,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { RiCloseCircleFill } from 'react-icons/ri';
import { PrismicRichText } from '@prismicio/react';
import { IconButton } from 'components';

export interface InfoDialogData {
  title: ReactNode;
  content: string | PrismicRichTextField;
}

interface Props {
  isOpen: boolean;
  onClose(): void;
  data: InfoDialogData | null;
}

export const InfoDialog = (props: Props) => {
  const { isOpen, onClose, data } = props;

  const { title, content } = data || {};

  const renderContent = () => {
    if (typeof content === 'string') {
      return (
        <Text as="span" whiteSpace="pre-line" fontSize="1rem">
          {content}
        </Text>
      );
    }

    return (
      <PrismicRichText
        field={content}
        components={{
          paragraph: ({ children }) => <Text fontSize="1rem">{children}</Text>,
        }}
      />
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent px="2rem">
        <ModalHeader px={0}>
          <HStack justify="space-between" w="full">
            <Text fontSize="1rem">{title}</Text>

            <IconButton
              onClick={onClose}
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              aria-label="close info dialog"
            >
              <Icon
                as={RiCloseCircleFill}
                fontSize="1.25rem"
                color="network.secondary"
              />
            </IconButton>
          </HStack>
        </ModalHeader>

        <Divider />
        <ModalBody px={0} pb={6}>
          <Text as="span" whiteSpace="pre-line" fontSize="1rem">
            {renderContent()}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
