const round = (value: number) => {
  const [integer, decimal = '0'] = String(value).split('.');
  const parsedInteger = Number(integer);
  const parsedDecimal = decimal.slice(0, 3);

  if (parsedDecimal.length < 3 || parsedDecimal.endsWith('0')) {
    return Number(value.toFixed(2));
  }

  const fullNumber = Number(`${parsedInteger}.${parsedDecimal}`) + 0.01;

  const [integerNumber, decimalNumber = '0'] = String(fullNumber).split('.');

  const roundedValue = `${integerNumber}.${decimalNumber.slice(0, 2)}0`;

  return Number(roundedValue);
};

const Fees = {
  FIXED: {
    OVER_OR_EQUAL_TO_75_PERCENT: 0.188,
    UNDER_75_PERCENT: 0.048,
  },
  REGULAR: {
    OVER_OR_EQUAL_TO_75_PERCENT: 0.119,
    UNDER_75_PERCENT: 0.014,
  },
  OUTLET_FEES: {
    OVER_OR_EQUAL_TO_75_PERCENT: 0.118,
    UNDER_75_PERCENT: 0.013,
  },
} as const;

interface FeeCalculatorParams {
  hasOutletFees: boolean;
  shareFeePercent: number;
}

export const calcNetworkFee = (params: FeeCalculatorParams) => {
  const { hasOutletFees, shareFeePercent } = params;

  const isGreaterThan75Percent = shareFeePercent >= 0.75;

  const resolveFees = () => {
    const fixed = isGreaterThan75Percent
      ? Fees.FIXED.OVER_OR_EQUAL_TO_75_PERCENT
      : Fees.FIXED.UNDER_75_PERCENT;

    const getVariant = () => {
      if (hasOutletFees) return Fees.OUTLET_FEES;

      return Fees.REGULAR;
    };

    return {
      fixed,
      variant: getVariant(),
    };
  };

  const { fixed, variant } = resolveFees();

  const variantFee = isGreaterThan75Percent
    ? variant.OVER_OR_EQUAL_TO_75_PERCENT
    : variant.UNDER_75_PERCENT;

  const maxDecimals = isGreaterThan75Percent ? 4 : 5;

  const fee =
    Number((fixed * shareFeePercent - variantFee).toFixed(maxDecimals)) * 100;

  const roundedFee = round(fee);

  return roundedFee > 0 ? roundedFee : 0;
};
