import {
  VStack,
  Box,
  HStack,
  Text,
  Center,
  Divider,
  Icon,
  SimpleGrid,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import { RiArrowDownSFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { IconButton } from 'components';
import { divideBy100, formatNumber, multiplyBy100 } from 'utils/helpers';
import { useDispatch, useSelector } from 'hooks/store';
import { select } from 'store';
import { InfoDialogData } from '../InfoDialog';
import {
  mapRealValueToSliderValue,
  mapSliderValueToRealValue,
} from '../../helpers';

const networkPricingSelector = select.networkPricing;

interface Props {
  onOpenInfoDialog(data: InfoDialogData): void;
  content: {
    networkFeeDescription: string;
    sharedFeeDescription: string;
    overSharedFeeDialogTitle: string;
    overSharedFeeDialogDescription: string;
  };
}

export const DesiredFee = (props: Props) => {
  const { onOpenInfoDialog, content } = props;

  const {
    networkFeeDescription,
    sharedFeeDescription,
    overSharedFeeDialogTitle,
    overSharedFeeDialogDescription,
  } = content;

  const networkFee = useSelector(networkPricingSelector.getNetworkFee);
  const sharedExpirationRate = useSelector(
    networkPricingSelector.getSharedExpirationRate,
  );
  const dispatch = useDispatch();

  const [slider, setSlider] = useState(() => {
    return mapRealValueToSliderValue(multiplyBy100(sharedExpirationRate));
  });

  const mappedSliderValue = mapSliderValueToRealValue(slider);

  useEffect(() => {
    if (divideBy100(mappedSliderValue) === sharedExpirationRate) return;

    setSlider(mapRealValueToSliderValue(multiplyBy100(sharedExpirationRate)));
  }, [sharedExpirationRate]);

  const formatNetworkFee = () => {
    const formattedNetworkFee = formatNumber({
      value: networkFee,
      minDecimalDigits: 2,
    });

    const [integer, decimal] = formattedNetworkFee.split(',');

    return {
      integer: `${integer},`,
      decimal,
    };
  };

  const formattedNetworkFee = formatNetworkFee();

  const debouncedChangeExpirationRate = useDebouncedCallback(
    (value: number) => {
      const mappedValue = divideBy100(mapSliderValueToRealValue(value));

      dispatch.networkPricing.setDesiredFees({
        sharedExpirationRate: mappedValue,
      });
    },
    25,
  );

  const onChangeSlider = (value: number) => {
    setSlider(value);

    debouncedChangeExpirationRate(value);
  };

  const onOpenOver85PercentInfoDialog = () => {
    onOpenInfoDialog({
      title: overSharedFeeDialogTitle,
      content: overSharedFeeDialogDescription,
    });
  };

  const isOver85Percent = sharedExpirationRate >= 0.9;

  return (
    <VStack spacing="1.5rem">
      <Box
        bg="white"
        color="blue.800"
        p="1.25rem"
        rounded="xl"
        w="full"
        shadow="0 0 1.25rem #00000067"
        pos="relative"
      >
        <HStack
          spacing="0.5rem"
          justify="space-between"
          alignItems="center"
          fontFamily="heading"
          w="full"
        >
          <Text
            fontSize="3.75rem"
            fontWeight="bold"
            whiteSpace="nowrap"
            w="50%"
            textAlign="center"
          >
            {formattedNetworkFee.integer}

            <Text as="span" fontSize="3rem">
              {formattedNetworkFee.decimal}

              <Text as="span" verticalAlign="super" fontSize="2rem">
                %
              </Text>
            </Text>
          </Text>

          <Center h="full">
            <Box h="5rem">
              <Divider orientation="vertical" borderColor="gray.100" h="full" />
            </Box>
          </Center>

          <Text fontSize="3.75rem" fontWeight="bold" w="50%" textAlign="center">
            {mappedSliderValue}
            <Text as="span" verticalAlign="super" fontSize="2rem">
              %
            </Text>
          </Text>
        </HStack>

        {isOver85Percent && (
          <IconButton
            aria-label="open alert dialog"
            pos="absolute"
            right="0.25rem"
            top="0.25rem"
            variant="ghost"
            _hover={{ color: 'network.secondary' }}
            _active={{ color: 'network.secondary' }}
            onClick={onOpenOver85PercentInfoDialog}
          >
            <Icon as={MdError} fontSize="1.75rem" color="red.500" />
          </IconButton>
        )}

        <SimpleGrid
          columns={2}
          fontSize="0.75rem"
          w="full"
          color={isOver85Percent ? 'red.500' : 'blue.800'}
        >
          <Text textAlign="center">{networkFeeDescription}</Text>

          <Text textAlign="center">{sharedFeeDescription}</Text>
        </SimpleGrid>
      </Box>

      <Box h="2rem" w="full">
        <Slider
          onChange={onChangeSlider}
          focusThumbOnChange={false}
          value={slider}
        >
          <SliderMark value={11} ml="-0.75rem" mt="-2rem">
            <Icon
              as={RiArrowDownSFill}
              fontSize="1.5rem"
              color="network.secondary"
            />
          </SliderMark>

          <SliderMark value={82} ml="-0.75rem" mt="-2rem">
            <Icon
              as={RiArrowDownSFill}
              fontSize="1.5rem"
              color={isOver85Percent ? 'red.500' : 'network.secondary'}
            />
          </SliderMark>

          <SliderTrack bg="white">
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            boxSize="1.5rem"
            bg="network.secondary"
            borderWidth="2px"
            borderStyle="solid"
            borderColor="white"
          />
        </Slider>
      </Box>
    </VStack>
  );
};
