import { ReactNode } from 'react';
import {
  VStack,
  HStack,
  Heading,
  Divider,
  List,
  Text,
  ListItem,
  Box,
  StackProps,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { animateComponentPresence } from '../animation';

export interface EvaluationRow {
  key: string;
  label: ReactNode;
  value: string | number;
  containerProps?: StackProps;
}

interface Props {
  title: string;
  description?: string;
  rows: EvaluationRow[];
}

export const EvaluationSection = (props: Props) => {
  const { title, description, rows } = props;

  const hasDescription = Boolean(description);

  return (
    <VStack align="flex-start" w="full">
      <HStack w="full" align="center" h="2.5rem" spacing="0.5rem">
        <Heading fontSize="1rem" color="network.secondary" whiteSpace="nowrap">
          {title}
        </Heading>

        <Divider
          w="full"
          borderColor="network.secondary"
          alignSelf="center"
          pt="0.75rem"
        />
      </HStack>

      {hasDescription && (
        <Box>
          <Text fontSize="0.75rem" maxW="55%" mt="-1rem">
            {description}
          </Text>
        </Box>
      )}

      <List justifyContent="flex-start" w="90%" minW="20rem" maxW="36.5rem">
        <AnimatePresence initial={false}>
          {rows.map(row => (
            <ListItem
              {...animateComponentPresence}
              key={row.key}
              as={motion.div}
            >
              <HStack {...row.containerProps} w="full" justify="space-between">
                <Text as="span">{row.label}</Text>

                <Text
                  key={row.value}
                  as={motion.span}
                  animate={{ scale: [1, 1.2, 1], transition: { delay: 0.25 } }}
                >
                  {row.value}
                </Text>
              </HStack>
            </ListItem>
          ))}
        </AnimatePresence>
      </List>
    </VStack>
  );
};
