import { Icon } from '@chakra-ui/react';
import { FaQuestionCircle } from 'react-icons/fa';
import { IconButton, IconButtonProps } from 'components';

type Props = Omit<IconButtonProps, 'aria-label'>;

export const QuestionButton = (props: Props) => {
  return (
    <IconButton
      variant="unstyled"
      whileHover={{ scale: 1.2 }}
      _hover={{ color: 'network.secondary' }}
      color="network.secondary"
      {...props}
      aria-label="open info"
    >
      <Icon as={FaQuestionCircle} fontSize="1.25rem" />
    </IconButton>
  );
};
