import { ReactNode } from 'react';
import { HStack, Heading, Divider, HeadingProps } from '@chakra-ui/react';

import { IconButtonProps } from 'components';
import { QuestionButton } from './QuestionButton';

type Variant = 'conditions' | 'variables';

interface Props {
  title: ReactNode;
  titleContainerProps?: HeadingProps;
  hideInfoButton?: boolean;
  infoButtonProps?: Partial<IconButtonProps>;
  variant?: Variant;
}

export const InfoRow = (props: Props) => {
  const {
    title,
    titleContainerProps = {},
    hideInfoButton = false,
    infoButtonProps = {},
    variant = 'conditions',
  } = props;

  const isConditionsVariant = variant === 'conditions';

  return (
    <HStack w="full" align="baseline" spacing="0.5rem">
      <Heading
        fontSize="1rem"
        color={isConditionsVariant ? 'network.secondary' : 'blue.800'}
        whiteSpace="nowrap"
        {...titleContainerProps}
      >
        {title}
      </Heading>

      <Divider
        w="full"
        borderColor={isConditionsVariant ? 'white' : 'gray.100'}
      />

      {!hideInfoButton && (
        <QuestionButton
          color={isConditionsVariant ? 'white' : 'gray.100'}
          {...infoButtonProps}
        />
      )}
    </HStack>
  );
};
