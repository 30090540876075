import { useState, ChangeEvent, useEffect, useRef } from 'react';
import {
  Divider,
  Heading,
  HStack,
  Icon,
  VStack,
  Text,
  Center,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Fade,
  Box,
} from '@chakra-ui/react';
import { GoCheck } from 'react-icons/go';

import { AnimatePresence, motion } from 'framer-motion';
import { select } from 'store';
import { useSelector, useDispatch } from 'hooks/store';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { animateComponentPresence } from '../../animation';
import { InfoRow } from '../InfoRow';
import { InfoDialogData } from '../InfoDialog';
import { QuestionButton } from '../QuestionButton';
import { DesiredFee } from './DesiredFee';

let desiredFeesObserver: IntersectionObserver;

type IncludedConditionItem = NetworkPricingConditions['includedConditions'][0];

const networkPricingSelector = select.networkPricing;

interface Props {
  onOpenInfoDialog(data: InfoDialogData): void;
  content: NetworkPricingConditions;
  outletsDialogDescription: NetworkPricingVariables['outletsDialogDescription'];
}

export const Conditions = (props: Props) => {
  const { onOpenInfoDialog, content, outletsDialogDescription } = props;

  const {
    title,
    desiredFee,
    desiredFeeDialogDescription,
    overSharedFeeDialogTitle,
    overSharedFeeDialogDescription,
    networkFeeDescription,
    sharedFeeDescription,
    paymentProvider,
    paymentProviderDialogDescription,
    outlets,
    outletsDescription,
    includedConditions,
  } = content;

  const [isDesiredFeesInView, setIsDesiredFeesInView] = useState(false);

  const { isMobile } = useResponsiveness();

  const dispatch = useDispatch();
  const isOutletFeesEnabled = useSelector(
    networkPricingSelector.getIsOutletFeesEnabled,
  );
  const showDefaultValues = useSelector(
    networkPricingSelector.getShowDefaultValues,
  );
  const paymentProviderFees = useSelector(
    networkPricingSelector.getPaymentProviderFeesFormatted,
  );

  const showFloatingDesiredFees = Boolean(isMobile) && !isDesiredFeesInView;

  const desiredFeesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMobile || !desiredFeesRef.current) return;

    desiredFeesObserver = new IntersectionObserver(([entry]) => {
      setIsDesiredFeesInView(entry.isIntersecting);
    });

    desiredFeesObserver.observe(desiredFeesRef.current);

    return () => {
      desiredFeesObserver.disconnect();
    };
  }, [desiredFeesRef, isMobile]);

  const desiredFeeContent = {
    networkFeeDescription,
    sharedFeeDescription,
    overSharedFeeDialogTitle,
    overSharedFeeDialogDescription,
  };

  const onChangeOutletFees = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch.networkPricing.setDesiredFees({
      isOutletFeesEnabled: event.target.checked,
    });
  };

  const onOpenDesiredFeeInfoDialog = () => {
    onOpenInfoDialog({
      title: desiredFee,
      content: desiredFeeDialogDescription,
    });
  };

  const onOpenPaymentProviderInfoDialog = () => {
    onOpenInfoDialog({
      title: paymentProvider,
      content: paymentProviderDialogDescription,
    });
  };

  const onOpenOutletsFeeInfoDialog = () => {
    onOpenInfoDialog({
      title: outlets,
      content: outletsDialogDescription,
    });
  };

  const onOpenIncludedConditionInfoDialog =
    (condition: IncludedConditionItem) => () => {
      onOpenInfoDialog({
        title: condition.title,
        content: condition.dialogDescriptionRichText,
      });
    };

  return (
    <VStack
      color="white"
      w="full"
      spacing="1.5rem"
      py="2rem"
      pt={['6rem', '6rem', '2rem']}
      px="1.5rem"
      bgGradient="linear(to-r, #2A416D, #1D2C4A)"
    >
      <Heading alignSelf="flex-start" fontSize="1.25rem">
        {title}
      </Heading>

      <Accordion
        as={Fade}
        in={showFloatingDesiredFees}
        allowToggle
        pos="fixed"
        w="full"
        top="-1.5rem"
        bgGradient="linear(to-r, #2A416D, #1D2C4A)"
        color="white"
        borderColor="transparent"
        zIndex={3}
        shadow="lg"
      >
        <AccordionItem>
          <AccordionPanel p="1.5rem">
            <DesiredFee
              onOpenInfoDialog={onOpenInfoDialog}
              content={desiredFeeContent}
            />
          </AccordionPanel>

          <AccordionButton py="1rem">
            <Heading w="full" textAlign="left" fontSize="1.25rem">
              {desiredFee}
            </Heading>

            <AccordionIcon />
          </AccordionButton>
        </AccordionItem>
      </Accordion>

      <InfoRow
        title={desiredFee}
        infoButtonProps={{ onClick: onOpenDesiredFeeInfoDialog }}
      />

      <Box ref={desiredFeesRef} w="full">
        <DesiredFee
          onOpenInfoDialog={onOpenInfoDialog}
          content={desiredFeeContent}
        />
      </Box>

      <InfoRow
        title={paymentProvider}
        infoButtonProps={{ onClick: onOpenPaymentProviderInfoDialog }}
      />

      <Center
        w="full"
        p="0.5rem"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="network.secondary"
        rounded="xl"
      >
        <Text fontFamily="heading" fontWeight="bold" fontSize="2rem">
          {paymentProviderFees.rate}
          <Text as="span" verticalAlign="super" fontSize="1rem" mr="0.5rem">
            %
          </Text>
          + {paymentProviderFees.fixed}
          <Text as="span" verticalAlign="super" fontSize="1rem">
            €
          </Text>
        </Text>
      </Center>

      <Box>
        <AnimatePresence initial={false}>
          {!showDefaultValues && (
            <VStack as={motion.div} {...animateComponentPresence}>
              <HStack w="full" justify="center">
                <Checkbox
                  size="lg"
                  variant="network"
                  onChange={onChangeOutletFees}
                  isChecked={isOutletFeesEnabled}
                />

                <InfoRow
                  title={outlets}
                  infoButtonProps={{ onClick: onOpenOutletsFeeInfoDialog }}
                />
              </HStack>

              <Text fontSize="0.75rem" pl="1.75rem">
                {outletsDescription}
              </Text>
            </VStack>
          )}
        </AnimatePresence>
      </Box>

      <Divider variant="dashed" />

      <VStack align="flex-start" w="full">
        {includedConditions.map(condition => (
          <HStack key={condition.title}>
            <HStack>
              <Icon as={GoCheck} fontSize="2rem" color="network.secondary" />

              <Text>{condition.title}</Text>
            </HStack>

            <QuestionButton
              color="white"
              onClick={onOpenIncludedConditionInfoDialog(condition)}
              size="xs"
            />
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};
