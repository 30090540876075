'use client';

import { useCallback, useEffect, useState } from 'react';
import { Flex, Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { useSearchParams } from 'next/navigation';
import { Provider } from 'react-redux';

import { store } from 'store';
import { useDispatch } from 'hooks/store';
import { divideBy100, multiplyBy100 } from 'utils/helpers';
import { usePageTextContent } from 'context/pageTextContent';

import { calcNetworkFee } from 'store/models/networkPricing/helpers';
import { Conditions, Variables } from './components';
import { AnnualEvaluation } from './components/AnnualEvaluation/AnnualEvaluation';
import { InfoDialog, InfoDialogData } from './components/InfoDialog';
import { maxFieldsValue } from './constants';
import { ShareButtons } from './components/ShareButtons';

const NetworkPricingPageBase = () => {
  const { content } = usePageTextContent<NetworkPricingContent>();

  const [infoDialogData, setInfoDialogData] = useState<InfoDialogData | null>(
    null,
  );

  const searchParams = useSearchParams();

  const getNumberQuery = (queryParam: string | null | undefined) => {
    if (!queryParam) return;

    const parsedNumber = Number(queryParam);

    if (Number.isNaN(parsedNumber)) return;

    return parsedNumber;
  };

  const slider = searchParams?.get('slider');
  const payoutEnabled = searchParams?.get('payout-enabled');
  const outletFees = searchParams?.get('outlet-fees');
  const revenue = searchParams?.get('revenue');
  const expires = searchParams?.get('expires');
  const online = searchParams?.get('online');

  const networkPricingDispatcher = useDispatch().networkPricing;
  const isOutletFeesEnabled = payoutEnabled === 'true';

  useEffect(() => {
    const { defaultValues } = content;

    networkPricingDispatcher.setDefaultValues({
      networkFee: calcNetworkFee({
        shareFeePercent: defaultValues.sliderPosition,
        hasOutletFees: isOutletFeesEnabled,
      }),
      sharedExpirationRate: defaultValues.sliderPosition,
      paymentProviderFees: {
        rate: multiplyBy100(defaultValues.onlineGiftcardFeesRate),
        fixed: divideBy100(defaultValues.onlineGiftcardFeesFixed),
      },
      isOutletFeesEnabled,

      revenue: divideBy100(defaultValues.annualNetworkRevenue),
      expirationRate: multiplyBy100(defaultValues.expirationRate),
      onlineShare: multiplyBy100(defaultValues.shareOnlineGiftcard),
      outletFees: {
        rate: multiplyBy100(defaultValues.outletFeesRate),
        fixed: divideBy100(defaultValues.outletFeesFixed),
      },

      averageGiftcard: {
        physical: defaultValues.averageGiftcardCredit,
        online: defaultValues.averageGiftcardCreditOnline,
      },
    });
  }, []);

  useEffect(() => {
    const sliderValue = getNumberQuery(slider);

    const hasSliderValue = sliderValue !== undefined;

    if (hasSliderValue && sliderValue <= 0) {
      networkPricingDispatcher.setDesiredFees({
        sharedExpirationRate: 0.1,
        isOutletFeesEnabled,
      });

      return;
    }

    if (hasSliderValue && sliderValue >= 1) {
      networkPricingDispatcher.setDesiredFees({
        sharedExpirationRate: 1,
        isOutletFeesEnabled,
      });

      return;
    }

    const sliderValuePrecision = hasSliderValue
      ? Number(sliderValue.toFixed(2))
      : content.defaultValues.sliderPosition;

    networkPricingDispatcher.setDesiredFees({
      sharedExpirationRate: sliderValuePrecision,
      isOutletFeesEnabled,
    });
  }, []);

  useEffect(() => {
    const revenueValue = getNumberQuery(revenue);

    if (!revenueValue) return;

    const normalizedRevenueValue = divideBy100(revenueValue);

    if (normalizedRevenueValue <= 0) {
      networkPricingDispatcher.setRevenue(0);

      return;
    }

    if (normalizedRevenueValue >= maxFieldsValue.annualRevenue) {
      networkPricingDispatcher.setRevenue(maxFieldsValue.annualRevenue);

      return;
    }

    networkPricingDispatcher.setRevenue(normalizedRevenueValue);
  }, []);

  useEffect(() => {
    if (!outletFees || typeof outletFees !== 'string') return;

    const [outletFeesRate, outletFeesFixed] = outletFees.split('|');

    const outletFeesRateValue = getNumberQuery(outletFeesRate);
    const outletFeesFixedValue = getNumberQuery(outletFeesFixed);

    if (
      outletFeesRateValue === undefined ||
      outletFeesFixedValue === undefined
    ) {
      return;
    }

    // whenever outlet fees has a value, we enable it and calculate the fees
    networkPricingDispatcher.setIsOutletFeesEnabled(true);
    networkPricingDispatcher.setDesiredFees({ isOutletFeesEnabled: true });
    networkPricingDispatcher.setOutletFees({
      fixed: divideBy100(outletFeesFixedValue),
      rate: multiplyBy100(outletFeesRateValue),
    });
  }, []);

  useEffect(() => {
    const expiresValue = getNumberQuery(expires);

    if (!expiresValue) return;

    if (expiresValue <= 0) {
      networkPricingDispatcher.setExpirationRate(0);

      return;
    }

    if (expiresValue >= divideBy100(maxFieldsValue.expirationRate.input)) {
      networkPricingDispatcher.setExpirationRate(
        maxFieldsValue.expirationRate.input,
      );

      return;
    }

    const expiresValuePrecision = multiplyBy100(expiresValue);

    networkPricingDispatcher.setExpirationRate(expiresValuePrecision);
  }, []);

  useEffect(() => {
    const onlineValue = getNumberQuery(online);

    if (!onlineValue) return;

    if (onlineValue <= 0) {
      networkPricingDispatcher.setOnlineShare(0);

      return;
    }

    if (onlineValue >= divideBy100(maxFieldsValue.onlineShare.input)) {
      networkPricingDispatcher.setOnlineShare(maxFieldsValue.onlineShare.input);

      return;
    }

    const onlineValuePrecision = multiplyBy100(onlineValue);

    networkPricingDispatcher.setOnlineShare(onlineValuePrecision);
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenInfoDialog = useCallback((data: InfoDialogData) => {
    setInfoDialogData(data);
    onOpen();
  }, []);

  return (
    <Stack
      direction={['column', 'column', 'row']}
      spacing={0}
      minH="100vh"
      h="full"
      w="full"
    >
      <InfoDialog isOpen={isOpen} onClose={onClose} data={infoDialogData} />

      <Stack
        direction={['column', 'column', 'row']}
        w="full"
        spacing={0}
        align="flex-start"
      >
        <Flex minH={['100vh', '100vh', 'full']} w="full">
          <Conditions
            onOpenInfoDialog={handleOpenInfoDialog}
            content={content.conditions}
            outletsDialogDescription={
              content.variables.outletsDialogDescription
            }
          />
        </Flex>

        <Flex
          minH={['100vh', '100vh', 'full']}
          minW="19.5rem"
          w="full"
          maxW={['full', 'full', '40%']}
        >
          <Variables
            onOpenInfoDialog={handleOpenInfoDialog}
            content={content.variables}
          />
        </Flex>
      </Stack>

      <VStack
        minH={['100vh', '100vh', 'full']}
        w="full"
        spacing="3rem"
        align="flex-start"
        py="2rem"
        pl="1.5rem"
        pr={0}
      >
        <AnnualEvaluation content={content.annualEvaluation} />

        <ShareButtons
          content={{
            requestAnOfferButton: content.annualEvaluation.requestAnOfferButton,
            requestAnOfferTo: content.annualEvaluation.requestAnOfferTo,
            requestAnOfferEmailSubject:
              content.annualEvaluation.requestAnOfferEmailSubject,
            requestAnOfferEmailMessage:
              content.annualEvaluation.requestAnOfferEmailMessage,
            shareCalculationButton:
              content.annualEvaluation.shareCalculationButton,
            shareCalculationEmailSubject:
              content.annualEvaluation.shareCalculationEmailSubject,
            shareCalculationEmailMessage:
              content.annualEvaluation.shareCalculationEmailMessage,
          }}
        />
      </VStack>
    </Stack>
  );
};

const NetworkPricingPage = () => {
  return (
    <Provider store={store}>
      <NetworkPricingPageBase />
    </Provider>
  );
};

export default NetworkPricingPage;
