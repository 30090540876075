export interface FixedAndRatedFees<T extends string | number> {
  fixed: T;
  rate: T;
}

export interface NetworkPricingSettings {
  networkFee: number;
  sharedExpirationRate: number;
  isOutletFeesEnabled: boolean;
  revenue: number;
  onlineShare: number;
  expirationRate: number;
  paymentProviderFees: FixedAndRatedFees<number>;
  outletFees: FixedAndRatedFees<number>;
  averageGiftcard: {
    physical: number;
    online: number;
  };
}

export const defaultSettings: NetworkPricingSettings = {
  networkFee: 2.2,
  sharedExpirationRate: 0.75,
  isOutletFeesEnabled: false,
  revenue: 100000,
  onlineShare: 6.5,
  expirationRate: 18.25,
  paymentProviderFees: { rate: 2.49, fixed: 0.35 },
  outletFees: { fixed: 0, rate: 2 },
  averageGiftcard: { physical: 32, online: 37.2 },
} as const;
