import { HStack } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';

import { useSelector } from 'hooks/store';
import { select } from 'store';
import { Button, ButtonProps } from 'components';
import { formatFloatToInteger, isServer, sendEmail } from 'utils/helpers';

const buttonStyles: ButtonProps = {
  bg: 'network.secondary',
  _hover: { bg: 'network.primary' },
  _active: { bg: 'network.primary' },
};

const networkPricingSelector = select.networkPricing;

const formatRate = (rate: number) => Number((rate / 100).toFixed(4));

interface Props {
  content: {
    requestAnOfferButton: string;
    requestAnOfferEmailMessage: string;
    requestAnOfferEmailSubject: string;
    requestAnOfferTo: string;
    shareCalculationButton: string;
    shareCalculationEmailMessage: string;
    shareCalculationEmailSubject: string;
  };
}

export const ShareButtons = (props: Props) => {
  const { content } = props;

  const {
    shareCalculationButton,
    shareCalculationEmailSubject,
    shareCalculationEmailMessage,
    requestAnOfferButton,
    requestAnOfferTo,
    requestAnOfferEmailSubject,
    requestAnOfferEmailMessage,
  } = content;

  const pathname = usePathname();

  const sharedExpirationRate = useSelector(
    networkPricingSelector.getSharedExpirationRate,
  );
  const isOutletFeesEnabled = useSelector(
    networkPricingSelector.getIsOutletFeesEnabled,
  );

  const revenue = useSelector(networkPricingSelector.getRevenue);
  const expirationRate = useSelector(networkPricingSelector.getExpirationRate);
  const onlineShare = useSelector(networkPricingSelector.getOnlineShare);
  const outletFeesFixed = useSelector(
    networkPricingSelector.getOutletFeesFixed,
  );
  const outletFeesRate = useSelector(networkPricingSelector.getOutletFeesRate);

  const buildShareUrl = () => {
    if (isServer() || !pathname) return '';

    const url = new URL(pathname, window.location.origin);

    url.searchParams.set('slider', String(sharedExpirationRate));
    url.searchParams.set('payout-enabled', String(isOutletFeesEnabled));
    url.searchParams.set('revenue', String(formatFloatToInteger(revenue)));
    url.searchParams.set('expires', String(formatRate(expirationRate)));
    url.searchParams.set('online', String(formatRate(onlineShare)));

    if (isOutletFeesEnabled) {
      url.searchParams.set(
        'outlet-fees',
        `${formatRate(outletFeesRate)}|${formatFloatToInteger(
          outletFeesFixed,
        )}`,
      );
    }

    return url.href;
  };

  const onClickShareCalculation = () => {
    const shareLink = buildShareUrl();

    const message = shareCalculationEmailMessage.replace('{{link}}', shareLink);

    sendEmail({ subject: shareCalculationEmailSubject, body: message });
  };

  const onClickRequestAnOffer = () => {
    const shareLink = buildShareUrl();

    const message = requestAnOfferEmailMessage.replace('{{link}}', shareLink);

    sendEmail({
      to: requestAnOfferTo,
      subject: requestAnOfferEmailSubject,
      body: message,
    });
  };

  return (
    <HStack w="90%" minW="20rem" maxW="36.5rem" justify="space-between">
      <Button {...buttonStyles} onClick={onClickShareCalculation}>
        {shareCalculationButton}
      </Button>

      <Button {...buttonStyles} onClick={onClickRequestAnOffer}>
        {requestAnOfferButton}
      </Button>
    </HStack>
  );
};
