import { formatCurrency, formatNumber } from 'utils/helpers';

export const mapSliderValueToRealValue = (value: number) => {
  if (value <= 10) return 10;

  const realValue = Math.round((value * 50 + 3900) / 89);

  return realValue;
};

export const mapRealValueToSliderValue = (value: number) => {
  if (value <= 10) return 10;

  const realValue = Math.round((89 * value - 3900) / 50);

  return realValue;
};

export const formatNetworkPricingCurrency = (value: number) => {
  return formatCurrency({ value });
};

export const formatNetworkPricingNumber = (value: number) => {
  return formatNumber({ value });
};

export const parseStringToNumber = (value?: string) => {
  const parsedValue = Number(value?.replace(',', '.'));

  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};
