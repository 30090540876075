import { useSelector } from 'hooks/store';
import { select } from 'store';
import { defaultSettings } from 'store/models/networkPricing/default';
import { divideBy100, multiplyBy100 } from 'utils/helpers';
import { usePageTextContent } from 'context/pageTextContent';
import {
  formatNetworkPricingCurrency,
  formatNetworkPricingNumber,
} from '../../helpers';

const networkPricingSelector = select.networkPricing;

export const useAnnualEvaluation = () => {
  const { content } = usePageTextContent<NetworkPricingContent>();

  const revenue = useSelector(networkPricingSelector.getRevenue);
  const sharedExpirationRate = useSelector(
    networkPricingSelector.getSharedExpirationRate,
  );
  const networkFee = useSelector(networkPricingSelector.getNetworkFee);
  const onlineShare = useSelector(networkPricingSelector.getOnlineShare);
  const expirationRate = useSelector(networkPricingSelector.getExpirationRate);
  const outletFeesFixed = useSelector(
    networkPricingSelector.getOutletFeesFixed,
  );
  const outletFeesRate = useSelector(networkPricingSelector.getOutletFeesRate);
  const paymentProviderFees = useSelector(
    networkPricingSelector.getPaymentProviderFees,
  );

  const parsedNetworkPricing = {
    networkFee: divideBy100(networkFee),
    revenue: multiplyBy100(revenue),
    onlineShare: divideBy100(onlineShare),
    expirationRate: divideBy100(expirationRate),
    outletFeesFixed: multiplyBy100(outletFeesFixed),
    outletFeesRate: divideBy100(outletFeesRate),
    paymentProviderFeesFixed: paymentProviderFees.fixed,
    paymentProviderFeesRate: divideBy100(paymentProviderFees.rate),
    avgGiftcards: multiplyBy100(defaultSettings.averageGiftcard.physical),
  };

  const formattedSharedExpirationRate = formatNetworkPricingNumber(
    multiplyBy100(sharedExpirationRate),
  );
  const formattedExpirationRate = formatNetworkPricingNumber(
    multiplyBy100(parsedNetworkPricing.expirationRate),
  );
  const formattedOnlineShare = formatNetworkPricingNumber(
    multiplyBy100(parsedNetworkPricing.onlineShare),
  );

  const onlineRevenue = Math.round(
    parsedNetworkPricing.revenue * parsedNetworkPricing.onlineShare,
  );

  const expiresFunds = Math.round(
    parsedNetworkPricing.revenue * parsedNetworkPricing.expirationRate,
  );

  const redeemFunds = Math.round(parsedNetworkPricing.revenue - expiresFunds);

  const onlineGiftcardsCount = Math.ceil(
    onlineRevenue / defaultSettings.averageGiftcard.online,
  );

  const giftcardCount = Math.ceil(
    onlineGiftcardsCount +
      (parsedNetworkPricing.revenue - onlineRevenue) /
        defaultSettings.averageGiftcard.physical,
  );

  const giftcardValue = formatNetworkPricingCurrency(
    defaultSettings.averageGiftcard.physical,
  );

  const onlineGiftcardValue = formatNetworkPricingCurrency(
    defaultSettings.averageGiftcard.online,
  );

  const calculateNetworkFeesBase = () => {
    if (networkFee === 0) return 0;

    const netowkFeesBase = Math.round(
      parsedNetworkPricing.revenue * parsedNetworkPricing.networkFee,
    );

    const { minNetworkFee } = content.defaultValues;

    if (netowkFeesBase < minNetworkFee) return minNetworkFee;

    return netowkFeesBase;
  };

  const networkFeesBase = calculateNetworkFeesBase();

  const networkFeesPaymentProvider = Math.round(
    onlineRevenue * parsedNetworkPricing.paymentProviderFeesRate +
      onlineGiftcardsCount * parsedNetworkPricing.paymentProviderFeesFixed,
  );

  const networkFeesTotal = Math.round(
    networkFeesBase + networkFeesPaymentProvider,
  );

  const networkGainsExpires = Math.round(expiresFunds * sharedExpirationRate);

  const networkGainsOutlets = Math.round(
    parsedNetworkPricing.revenue * parsedNetworkPricing.outletFeesRate +
      (redeemFunds / parsedNetworkPricing.avgGiftcards) *
        parsedNetworkPricing.outletFeesFixed,
  );

  const annualEvaluation = {
    revenueAmount: divideBy100(parsedNetworkPricing.revenue),
    formattedOnlineShare,
    formattedExpirationRate,
    formattedSharedExpirationRate,
    onlineRevenue: divideBy100(onlineRevenue),
    expiresFunds: divideBy100(expiresFunds),
    redeemFunds: divideBy100(redeemFunds),
    onlineGiftcardsCount: Math.round(divideBy100(onlineGiftcardsCount)),
    giftcardCount: Math.round(divideBy100(giftcardCount)),
    giftcardValue,
    onlineGiftcardValue,
    networkFeesBase: divideBy100(networkFeesBase),
    networkFeesPaymentProvider: divideBy100(networkFeesPaymentProvider),
    networkFeesTotal: divideBy100(networkFeesTotal),
    networkGainsExpires: divideBy100(networkGainsExpires),
    networkGainsOutlets: divideBy100(networkGainsOutlets),
  };

  return { annualEvaluation };
};
