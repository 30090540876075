export const maxFieldsValue = {
  annualRevenue: 10000000, // 10M
  onlineShare: {
    input: 100,
    slider: 50,
  },
  expirationRate: {
    input: 100,
    slider: 50,
  },
  outlets: {
    fixed: 10,
    rate: 10,
  },
} as const;
