'use client';

import { FormikConfig, FormikValues, useFormik } from 'formik';
import { AnyObjectSchema } from 'yup';

interface FormParams<T extends FormikValues> extends FormikConfig<T> {
  onSubmit(data: T): void;
  initialValues: T;
  validationSchema?: AnyObjectSchema;
}

export const useForm = <T extends FormikValues>(params: FormParams<T>) => {
  const formik = useFormik<T>({
    validateOnChange: false,
    ...params,
  });

  const getError = (field: keyof T) => formik.errors[field];

  const setValue = (field: keyof T, value: T[keyof T]) => {
    formik.setFieldValue(String(field), value, false);
  };

  const setTouched = (field: keyof T, touched = true) => {
    formik.setFieldTouched(String(field), touched);
  };

  const resetValues = (fields: Array<keyof T>) => {
    fields.forEach(field => {
      setValue(field, params.initialValues[field]);
    });
  };

  const resetTouch = (fields: Array<keyof T>) => {
    fields.forEach(field => {
      setTouched(field, false);
    });
  };

  const getFieldProps = (field: keyof T) => ({
    id: field,
    name: field,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: getError(field),
    value: formik.values[field],
  });

  return {
    getError,
    setValue,
    resetForm: formik.resetForm,
    setTouched,
    resetTouch,
    resetValues,
    getFieldProps,
    submitForm: formik.submitForm,
    validateForm: formik.validateForm,
    isFormValid: formik.isValid,
    errors: formik.errors,
    values: formik.values,
    setValues: formik.setValues,
    handleSubmit: formik.handleSubmit,
    isSubmitting: formik.isSubmitting,
    setErrors: formik.setErrors,
  };
};
