import { Heading, VStack } from '@chakra-ui/react';

import { EvaluationRow, EvaluationSection } from '../EvaluationSection';
import { formatNetworkPricingCurrency } from '../../helpers';

import { useAnnualEvaluation } from './useAnnualEvaluation';

interface Props {
  content: NetworkPricingAnnualEvaluation;
}

export const AnnualEvaluation = (props: Props) => {
  const { content } = props;

  const {
    title,
    hardFacts,
    totalSales,
    fromOnline,
    totalExpired,
    totalGiftcards,
    giftcardAmount,
    onlineGiftcardAmount,
    basicFees,
    paymentProviderFees,
    networkExpirationGains,
    networkOutletsGains,
    costsAndGains,
    networkGainsTotal,
    from,
    expirations,
  } = content;

  const { annualEvaluation } = useAnnualEvaluation();

  const {
    revenueAmount,
    formattedOnlineShare,
    onlineRevenue,
    giftcardCount,
    onlineGiftcardsCount,
    giftcardValue,
    onlineGiftcardValue,

    networkFeesBase,
    networkFeesPaymentProvider,
    networkGainsOutlets,

    expiresFunds,
    formattedExpirationRate,
    formattedSharedExpirationRate,
    networkGainsExpires,
  } = annualEvaluation;

  const buildHardFactsRows = (): EvaluationRow[] => {
    return [
      {
        key: 'total-sales',
        label: totalSales,
        value: formatNetworkPricingCurrency(revenueAmount),
      },
      {
        key: 'from-online-rate',
        label: `${fromOnline} (${formattedOnlineShare}%)`,
        value: formatNetworkPricingCurrency(onlineRevenue),
      },

      {
        key: 'total-giftcards',
        label: totalGiftcards,
        value: giftcardCount,
        containerProps: { mt: '0.5rem' },
      },
      {
        key: 'from-online-count',
        label: fromOnline,
        value: onlineGiftcardsCount,
        containerProps: { mb: '0.5rem' },
      },
      { key: 'giftcard-amount', label: giftcardAmount, value: giftcardValue },
      {
        key: 'online-giftcard-amount',
        label: onlineGiftcardAmount,
        value: onlineGiftcardValue,
      },
    ];
  };

  const buildCostsAndGainsRows = (): EvaluationRow[] => {
    const rows: EvaluationRow[] = [
      {
        key: 'basic-fees',
        label: basicFees,
        value: `-${formatNetworkPricingCurrency(networkFeesBase)}`,
      },
      {
        key: 'payment-provider-fees',
        label: paymentProviderFees,
        value: `-${formatNetworkPricingCurrency(networkFeesPaymentProvider)}`,
      },
      {
        key: 'network-outlets-gains',
        label: networkOutletsGains,
        value: formatNetworkPricingCurrency(networkGainsOutlets),
      },
      {
        key: 'network-gains-total',
        label: networkGainsTotal,
        value: formatNetworkPricingCurrency(
          networkGainsOutlets - networkFeesBase - networkFeesPaymentProvider,
        ),
        containerProps: { fontWeight: 'bold' },
      },
    ];

    if (networkGainsOutlets === 0) {
      const outletGainsIndex = rows.findIndex(
        row => row.label === networkOutletsGains,
      );

      rows.splice(outletGainsIndex, 1);
    }

    return rows;
  };

  const buildExpirationsRows = (): EvaluationRow[] => {
    return [
      {
        key: 'total-expired',
        label: `${totalExpired} (${formattedExpirationRate}%)`,
        value: formatNetworkPricingCurrency(expiresFunds),
        containerProps: { mt: '0.5rem' },
      },
      {
        key: 'network-expiration-gains',
        label: `${networkExpirationGains} (${formattedSharedExpirationRate}% ${from})`,
        value: formatNetworkPricingCurrency(networkGainsExpires),
        containerProps: { fontWeight: 'bold' },
      },
    ];
  };

  const hardFactsRows = buildHardFactsRows();
  const costsAndGainsRows = buildCostsAndGainsRows();
  const expirationsRows = buildExpirationsRows();

  return (
    <VStack bg="white" align="flex-start" w="full" spacing="1.5rem">
      <Heading alignSelf="flex-start" fontSize="1.25rem">
        {title}
      </Heading>

      <EvaluationSection title={hardFacts} rows={hardFactsRows} />

      <EvaluationSection title={costsAndGains} rows={costsAndGainsRows} />

      <EvaluationSection title={expirations} rows={expirationsRows} />
    </VStack>
  );
};
