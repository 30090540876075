import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Heading,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useDebouncedCallback } from 'use-debounce';

import { useForm } from 'hooks/useForm';
import { useDispatch, useSelector } from 'hooks/store';
import { select } from 'store';
import { InfoRow } from '../InfoRow';
import { PricingInput } from '../PricingInput';
import { InfoDialogData } from '../InfoDialog';
import { parseStringToNumber } from '../../helpers';
import { maxFieldsValue } from '../../constants';

const networkPricingSelector = select.networkPricing;

interface VariablesForm {
  annualRevenue: string;
  onlineShare: string;
  expirationRate: string;
  outletsFixed: string;
  outletsRate: string;
}

interface Props {
  onOpenInfoDialog(data: InfoDialogData): void;
  content: NetworkPricingVariables;
}

export const Variables = (props: Props) => {
  const { onOpenInfoDialog, content } = props;

  const {
    title,
    giftcardsSales,
    giftcardsSalesDialogDescription,
    giftcardsSalesDescription,
    expirationRate: expirationRateContent,
    expirationRateDialogDescription,
    expirationRateDescription,
    onlineShare: onlineShareContent,
    onlineShareDialogDescription,
    onlineShareDescription,
    outlets,
    outletsDialogDescription,
    outletsDescription,
    amountTooHigh,
    amountTooLow,
    percentageTooHigh,
    percentageTooLow,
  } = content;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        annualRevenue: yup
          .number()
          .transform((_, original: string) => parseStringToNumber(original))
          .min(1, amountTooLow)
          .max(maxFieldsValue.annualRevenue, amountTooHigh)
          .required(),
        onlineShare: yup
          .number()
          .transform((_, original: string) => parseStringToNumber(original))
          .min(1, percentageTooLow)
          .max(maxFieldsValue.onlineShare.input, percentageTooHigh)
          .required(),
        expirationRate: yup
          .number()
          .transform((_, original: string) => parseStringToNumber(original))
          .min(1, percentageTooLow)
          .max(maxFieldsValue.expirationRate.input, percentageTooHigh)
          .required(),
        outletsFixed: yup
          .number()
          .transform((_, original: string) => parseStringToNumber(original))
          .min(0, amountTooLow)
          .max(maxFieldsValue.outlets.fixed, amountTooHigh)
          .required(),
        outletsRate: yup
          .number()
          .transform((_, original: string) => parseStringToNumber(original))
          .min(0, percentageTooLow)
          .max(maxFieldsValue.outlets.rate, percentageTooHigh),
      }),
    [amountTooHigh, amountTooLow, percentageTooHigh, percentageTooLow],
  );

  const dispatch = useDispatch();
  const networkPricingDispatcher = dispatch.networkPricing;

  const showDefaultValues = useSelector(
    networkPricingSelector.getShowDefaultValues,
  );
  const isOutletFeesEnabled = useSelector(
    networkPricingSelector.getIsOutletFeesEnabled,
  );
  const revenue = useSelector(networkPricingSelector.getRevenue);
  const onlineShare = useSelector(networkPricingSelector.getOnlineShare);
  const expirationRate = useSelector(networkPricingSelector.getExpirationRate);
  const outletFeesFixed = useSelector(
    networkPricingSelector.getOutletFeesFixed,
  );
  const outletDefaultFeesFixed = useSelector(
    state => state.networkPricing.defaultValues.outletFees.fixed,
  );
  const outletFeesRate = useSelector(networkPricingSelector.getOutletFeesRate);
  const outletDefaultFeesRate = useSelector(
    state => state.networkPricing.defaultValues.outletFees.rate,
  );

  const [expirationRateSlider, setExpirationRateSlider] =
    useState(expirationRate);
  const [onlineShareSlider, setOnlineShareSlider] = useState(onlineShare);

  useEffect(() => {
    if (expirationRate === expirationRateSlider) return;

    setExpirationRateSlider(Math.round(expirationRate));
  }, [expirationRate]);

  useEffect(() => {
    if (onlineShare === onlineShareSlider) return;

    setOnlineShareSlider(Math.round(onlineShare));
  }, [expirationRate]);

  const initialValues = {
    annualRevenue: String(revenue),
    onlineShare: String(onlineShare),
    expirationRate: String(expirationRate),
    outletsFixed: String(outletFeesFixed),
    outletsRate: String(outletFeesRate),
  };

  const { getFieldProps, setValue } = useForm<VariablesForm>({
    onSubmit: () => null,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  const debouncedOnChangeExpirationRate = useDebouncedCallback(
    (value: number) => {
      networkPricingDispatcher.setExpirationRate(value);
    },
    25,
  );

  const onChangeExpirationRate = (value: number) => {
    setExpirationRateSlider(value);
    debouncedOnChangeExpirationRate(value);
  };

  const debouncedOnChangeOnlineShare = useDebouncedCallback((value: number) => {
    networkPricingDispatcher.setOnlineShare(value);
  }, 25);

  const onChangeOnlineShare = (value: number) => {
    setOnlineShareSlider(value);
    debouncedOnChangeOnlineShare(value);
  };

  const onValueChange =
    (name: keyof VariablesForm) =>
    (value = '0') => {
      type OnChangeHandler = Record<
        keyof VariablesForm,
        (newValue: number) => void
      >;

      const onChangeHandler: OnChangeHandler = {
        annualRevenue: networkPricingDispatcher.setRevenue,
        onlineShare: onChangeOnlineShare,
        expirationRate: onChangeExpirationRate,
        outletsFixed: (newValue = 0) =>
          networkPricingDispatcher.setOutletFees({ fixed: newValue }),
        outletsRate: (newValue = 0) =>
          networkPricingDispatcher.setOutletFees({ rate: newValue }),
      };

      const parsedValue = parseStringToNumber(value);

      onChangeHandler[name](parsedValue);

      setValue(name, value);
    };

  const onChangeSlider =
    (name: 'onlineShare' | 'expirationRate') => (value: number) => {
      const newValue = String(value);

      if (name === 'onlineShare') {
        onValueChange('onlineShare')(newValue);
        return;
      }

      onValueChange('expirationRate')(newValue);
    };

  const parseFieldProps = (name: keyof VariablesForm) => {
    const fieldProps = getFieldProps(name) as Partial<
      ReturnType<typeof getFieldProps>
    >;

    delete fieldProps.onChange;

    const getValue = () => {
      if (!showDefaultValues) return fieldProps.value;

      const defaultValue: Partial<VariablesForm> = {
        outletsFixed: String(outletDefaultFeesFixed),
        outletsRate: String(outletDefaultFeesRate),
      };

      return defaultValue[name] || fieldProps.value;
    };

    return {
      ...fieldProps,
      onValueChange: onValueChange(name),
      value: getValue(),
    };
  };

  const onOpenRevenueInfoDialog = () => {
    onOpenInfoDialog({
      title: giftcardsSales,
      content: giftcardsSalesDialogDescription,
    });
  };

  const onOpenExpirationRateInfoDialog = () => {
    onOpenInfoDialog({
      title: expirationRateContent,
      content: expirationRateDialogDescription,
    });
  };

  const onOpenOnlineShareInfoDialog = () => {
    onOpenInfoDialog({
      title: onlineShareContent,
      content: onlineShareDialogDescription,
    });
  };

  const onOpenOutletsFeeInfoDialog = () => {
    onOpenInfoDialog({
      title: outlets,
      content: outletsDialogDescription,
    });
  };

  return (
    <VStack
      w="full"
      minW="16rem"
      spacing="1.5rem"
      py="2rem"
      px="1.5rem"
      bg="gray.50"
    >
      <Heading
        alignSelf="flex-start"
        fontSize="1.25rem"
        color="network.secondary"
      >
        {title}
      </Heading>

      <VStack align="flex-start" w="full">
        <InfoRow
          title={giftcardsSales}
          variant="variables"
          infoButtonProps={{ onClick: onOpenRevenueInfoDialog }}
        />

        <Text fontSize="0.75rem">{giftcardsSalesDescription}</Text>

        <PricingInput
          {...parseFieldProps('annualRevenue')}
          maxValue={maxFieldsValue.annualRevenue}
        />
      </VStack>

      <VStack align="flex-start" w="full">
        <InfoRow
          title={expirationRateContent}
          variant="variables"
          infoButtonProps={{ onClick: onOpenExpirationRateInfoDialog }}
        />

        <Text fontSize="0.75rem">{expirationRateDescription}</Text>

        <PricingInput
          mask="percentage"
          {...parseFieldProps('expirationRate')}
          decimalsLimit={2}
          maxValue={maxFieldsValue.expirationRate.input}
        />

        <Slider
          onChange={onChangeSlider('expirationRate')}
          value={expirationRateSlider}
          focusThumbOnChange={false}
          max={maxFieldsValue.expirationRate.slider}
        >
          <SliderTrack bg="gray.100">
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            boxSize="1.5rem"
            bg="network.secondary"
            borderWidth="2px"
            borderStyle="solid"
            borderColor="white"
          />
        </Slider>
      </VStack>

      <VStack align="flex-start" w="full">
        <InfoRow
          title={onlineShareContent}
          variant="variables"
          infoButtonProps={{ onClick: onOpenOnlineShareInfoDialog }}
        />

        <Text fontSize="0.75rem">{onlineShareDescription}</Text>

        <PricingInput
          mask="percentage"
          {...parseFieldProps('onlineShare')}
          maxValue={maxFieldsValue.onlineShare.input}
          isDisabled={showDefaultValues}
          decimalsLimit={2}
        />

        <Slider
          onChange={onChangeSlider('onlineShare')}
          value={onlineShareSlider}
          focusThumbOnChange={false}
          max={maxFieldsValue.onlineShare.slider}
          isDisabled={showDefaultValues}
        >
          <SliderTrack bg="gray.100">
            <SliderFilledTrack bg="transparent" />
          </SliderTrack>

          <SliderThumb
            boxSize="1.5rem"
            bg="network.secondary"
            borderWidth="2px"
            borderStyle="solid"
            borderColor="white"
          />
        </Slider>
      </VStack>

      <Box>
        <VStack align="flex-start" w="full">
          <InfoRow
            title={outlets}
            variant="variables"
            infoButtonProps={{ onClick: onOpenOutletsFeeInfoDialog }}
          />

          <Text fontSize="0.75rem">{outletsDescription}</Text>

          <HStack w="full" justify="space-between">
            <PricingInput
              mask="percentage"
              minW="5.75rem"
              {...parseFieldProps('outletsRate')}
              decimalsLimit={2}
              maxValue={maxFieldsValue.outlets.rate}
              disabled={showDefaultValues || !isOutletFeesEnabled}
            />

            <Text
              textAlign="center"
              fontFamily="heading"
              fontSize="1.5rem"
              fontWeight="bold"
            >
              +
            </Text>

            <PricingInput
              minW="5.75rem"
              {...parseFieldProps('outletsFixed')}
              decimalsLimit={2}
              maxValue={maxFieldsValue.outlets.fixed}
              disabled={showDefaultValues || !isOutletFeesEnabled}
            />
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
};
