/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { createModel } from '@rematch/core';
import { formatNumber } from 'utils/helpers';
import { RootModel } from '../models';
import { calcNetworkFee } from './helpers';
import {
  NetworkPricingSettings,
  FixedAndRatedFees,
  defaultSettings,
} from './default';

interface NetworkPricingState {
  showDefaultValues: boolean;
  defaultValues: NetworkPricingSettings;
  input: NetworkPricingSettings;
}

const initialState: NetworkPricingState = {
  showDefaultValues: false,
  defaultValues: defaultSettings,
  input: defaultSettings,
};

const minSharedExpirationRate = 0.1;

interface SetDesiredFeesPayload {
  sharedExpirationRate?: number;
  isOutletFeesEnabled?: boolean;
}

export const networkPricing = createModel<RootModel>()({
  name: 'networkPricing',
  state: initialState,
  reducers: {
    setSharedExpirationRate(state, payload?: number) {
      if (payload === undefined) return state;

      const isMinValue = payload <= minSharedExpirationRate;

      const sharedExpirationRate = isMinValue
        ? minSharedExpirationRate
        : payload;

      state.showDefaultValues = isMinValue;
      state.input.sharedExpirationRate = sharedExpirationRate;
    },

    setNetworkFee(state, payload: number) {
      state.input.networkFee = payload;
    },
    setIsOutletFeesEnabled(state, payload?: boolean) {
      if (payload === undefined) return state;

      state.input.isOutletFeesEnabled = payload;
    },

    setRevenue(state, payload: number) {
      state.input.revenue = payload;
    },
    setOnlineShare(state, payload: number) {
      state.input.onlineShare = payload;
    },
    setExpirationRate(state, payload: number) {
      state.input.expirationRate = payload;
    },
    setOutletFees(state, payload: Partial<FixedAndRatedFees<number>>) {
      state.input.outletFees = {
        ...state.input.outletFees,
        ...payload,
      };
    },
    setDefaultValues(state, payload: Partial<NetworkPricingSettings>) {
      const networkPricingValues: NetworkPricingSettings = {
        ...state.defaultValues,
        ...payload,
      };

      state.input = networkPricingValues;
      state.defaultValues = networkPricingValues;
    },
  },
  selectors: (slice, createSelector) => ({
    getPaymentProviderFees() {
      return slice<FixedAndRatedFees<number>>(state => {
        const { showDefaultValues, input } = state;

        if (showDefaultValues) return { fixed: 0, rate: 0 };

        return input.paymentProviderFees;
      });
    },
    getPaymentProviderFeesFormatted(): () => FixedAndRatedFees<string> {
      // @ts-ignore
      return createSelector(
        this.getPaymentProviderFees,
        (paymentProviderFees: FixedAndRatedFees<number>) => {
          const { fixed, rate } = paymentProviderFees;

          return {
            fixed: formatNumber({ value: fixed, minDecimalDigits: 2 }),
            rate: formatNumber({ value: rate, minDecimalDigits: 2 }),
          };
        },
      );
    },

    getSharedExpirationRate() {
      return slice(state => state.input.sharedExpirationRate);
    },
    getNetworkFee() {
      return slice(state => state.input.networkFee);
    },
    getShowDefaultValues() {
      return slice(state => state.showDefaultValues);
    },
    getIsOutletFeesEnabled() {
      return slice(state => state.input.isOutletFeesEnabled);
    },
    getRevenue() {
      return slice(state => state.input.revenue);
    },
    getOnlineShare() {
      return slice(state => {
        const { input, defaultValues, showDefaultValues } = state;

        if (showDefaultValues) return defaultValues.onlineShare;

        return input.onlineShare;
      });
    },
    getExpirationRate() {
      return slice(state => state.input.expirationRate);
    },
    getOutletFeesRate() {
      return slice(state => {
        const { input, showDefaultValues } = state;

        if (!input.isOutletFeesEnabled || showDefaultValues) return 0;

        return input.outletFees.rate;
      });
    },
    getOutletFeesFixed() {
      return slice(state => {
        const { input, showDefaultValues } = state;

        if (!input.isOutletFeesEnabled || showDefaultValues) return 0;

        return input.outletFees.fixed;
      });
    },
  }),

  effects: dispatch => ({
    setDesiredFees(payload: SetDesiredFeesPayload, state) {
      const { sharedExpirationRate, isOutletFeesEnabled } = payload;

      dispatch.networkPricing.setSharedExpirationRate(sharedExpirationRate);
      dispatch.networkPricing.setIsOutletFeesEnabled(isOutletFeesEnabled);

      const sharedExpirationRateValue =
        sharedExpirationRate ?? state.networkPricing.input.sharedExpirationRate;

      const isOutletFeesEnabledValue =
        isOutletFeesEnabled ?? state.networkPricing.input.isOutletFeesEnabled;

      if (sharedExpirationRateValue <= minSharedExpirationRate) {
        dispatch.networkPricing.setNetworkFee(0);
        return;
      }

      const networkFee = calcNetworkFee({
        hasOutletFees: isOutletFeesEnabledValue,
        shareFeePercent: sharedExpirationRateValue,
      });

      dispatch.networkPricing.setNetworkFee(networkFee);
    },
  }),
});
